@import url('https://fonts.googleapis.com/css2?family=Nanum+Myeongjo:wght@400;700;800&display=swap');

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    margin: 0; /* Reset the margin on the body element */
}

:root{
    --almost-there-void: #1E1E1E;
    --not-quite-the-void: #2C2C2C;
    --bone-white: #FDFCF9;
}

html {
    font-family: 'Nanum Myeongjo', serif;
    font-size: 16px;
    color: var(--bone-white);
    background-color: var(--almost-there-void);
}

@media (max-width: 900px) {
    html { font-size: 15px; }
}
@media (max-width: 400px) {
    html { font-size: 13px; }
}

h1 {
    font-family: 'Needleteeth';
    font-size: 5em;
    text-shadow:
            -3px -3px 0 var(--almost-there-void),
            3px -3px 0 var(--almost-there-void),
            -3px 3px 0 var(--almost-there-void),
            3px 3px 0 var(--almost-there-void);
}
h2 {
    font-size: 2em;
}
h3 {
    font-size: 1.5em;
}
